exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-b-index-tsx": () => import("./../../../src/pages/b/index.tsx" /* webpackChunkName: "component---src-pages-b-index-tsx" */),
  "component---src-pages-download-contents-index-tsx": () => import("./../../../src/pages/download/contents/index.tsx" /* webpackChunkName: "component---src-pages-download-contents-index-tsx" */),
  "component---src-pages-download-dxdays-2024-index-tsx": () => import("./../../../src/pages/download/dxdays2024/index.tsx" /* webpackChunkName: "component---src-pages-download-dxdays-2024-index-tsx" */),
  "component---src-pages-download-gfhd-index-tsx": () => import("./../../../src/pages/download/gfhd/index.tsx" /* webpackChunkName: "component---src-pages-download-gfhd-index-tsx" */),
  "component---src-pages-download-index-tsx": () => import("./../../../src/pages/download/index.tsx" /* webpackChunkName: "component---src-pages-download-index-tsx" */),
  "component---src-pages-download-publics-index-tsx": () => import("./../../../src/pages/download/publics/index.tsx" /* webpackChunkName: "component---src-pages-download-publics-index-tsx" */),
  "component---src-pages-download-thanks-index-tsx": () => import("./../../../src/pages/download/thanks/index.tsx" /* webpackChunkName: "component---src-pages-download-thanks-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-index-tsx": () => import("./../../../src/pages/members/index.tsx" /* webpackChunkName: "component---src-pages-members-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-pages-how-to-use-index-tsx": () => import("./../../../src/pages/pages/how_to_use/index.tsx" /* webpackChunkName: "component---src-pages-pages-how-to-use-index-tsx" */),
  "component---src-pages-questions-index-tsx": () => import("./../../../src/pages/questions/index.tsx" /* webpackChunkName: "component---src-pages-questions-index-tsx" */),
  "component---src-pages-statics-about-virtual-fitting-index-tsx": () => import("./../../../src/pages/statics/about_virtual_fitting/index.tsx" /* webpackChunkName: "component---src-pages-statics-about-virtual-fitting-index-tsx" */),
  "component---src-pages-templates-knowledge-template-tsx": () => import("./../../../src/pages/templates/KnowledgeTemplate.tsx" /* webpackChunkName: "component---src-pages-templates-knowledge-template-tsx" */),
  "component---src-pages-templates-release-template-tsx": () => import("./../../../src/pages/templates/ReleaseTemplate.tsx" /* webpackChunkName: "component---src-pages-templates-release-template-tsx" */),
  "component---src-pages-updates-index-tsx": () => import("./../../../src/pages/updates/index.tsx" /* webpackChunkName: "component---src-pages-updates-index-tsx" */),
  "component---src-pages-updates-login-tsx": () => import("./../../../src/pages/updates/login.tsx" /* webpackChunkName: "component---src-pages-updates-login-tsx" */)
}

