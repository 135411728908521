
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBwoeq9ow7RPvGnFIcfWSDon0jNGQnMqTY",
    authDomain: "sally-lp-fff2c.firebaseapp.com",
    projectId: "sally-lp-fff2c",
    storageBucket: "sally-lp-fff2c.appspot.com",
    messagingSenderId: "437105275987",
    appId: "1:437105275987:web:d9abce960cdd6ecdba02a6",
    measurementId: "G-V7B0GWQYE3"
};

initializeApp(firebaseConfig);
// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// @ts-ignore
// const analytics = getAnalytics(app);